<template>
  <nav class="bg-white shadow-sm">
    <div class="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex items-center flex-shrink-0">
            <img class="block w-auto h-8 lg:hidden" src="@/assets/logo.png" alt="Post Office">
            <img class="hidden w-auto h-8 lg:block" src="@/assets/logo.png" alt="Post Office">
          </div>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <router-link to="/" class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 border-pol-red">Search</router-link>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center" v-if="user">
          <span class="pr-2 mr-1 text-xs border-r border-gray-200">Logged in as <span class="inline-flex px-2 ml-1 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">{{ user.name }}</span></span>
          <button @click="logout()" type="button" class="p-1 text-black bg-white rounded-full hover:text-pol-red">
            <span class="sr-only">Logout</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </button>
        </div>
        <div class="flex items-center -mr-2 sm:hidden">
          <button @click="menuOpen = !menuOpen" type="button" class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100" aria-controls="mobile-menu" :aria-expanded="menuOpen">
            <span class="sr-only">Open main menu</span>
            <svg :class="{'hidden' : menuOpen, 'block': !menuOpen}" class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg :class="{'hidden' : !menuOpen, 'block': menuOpen}" class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="sm:hidden" id="mobile-menu" v-if="menuOpen">
      <div class="pt-2 pb-3 space-y-1">
        <router-link to="/" class="block py-2 pl-3 pr-4 text-base font-medium border-l-4 bg-gray-50 border-pol-red text-pol-red">Dashboard</router-link>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200" v-if="user">
        <div class="flex items-center px-4">
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ user.name }}</div>
            <div class="text-sm font-medium text-gray-500">{{ user.email }}</div>
          </div>
          <button type="button" class="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500">
            <span class="sr-only">Logout</span>
            <svg @click="logout()" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import { Auth, Hub } from 'aws-amplify'

export default {
  name: 'Header',
  data () {
    return {
      menuOpen: false,
      user: null
    }
  },
  async created () {
    try {
      const { attributes } = await Auth.currentAuthenticatedUser()
      this.user = {
        name: attributes.name
      }
    } catch (error) {
      console.log('error getting current user: ', error)
    }

    Hub.listen('auth', async (data) => {
      if (data.payload.event === 'signIn') {
        const { attributes } = await Auth.currentAuthenticatedUser()
        this.user = {
          name: attributes.name
        }
      }
    })
  },
  methods: {
    async logout () {
      try {
        if (confirm('Are you sure you want to logout?')) {
          await Auth.signOut({ global: true })
          this.user = null
          this.$router.push({ name: 'login' })
        }
      } catch (error) {
        console.log('error signing out: ', error)
      }
    }
  }
}
</script>
